
/* You can add global styles to this file, and also import other style files */
/**
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*/
body{
    //font-family: 'Fivo Sans', sans-serif;
    //font-weight: 300;
    background-color: #151A30;
    color: #fff;
    font-family: 'Fivo Sans', sans-serif;
}


.main-content{
  overflow: hidden;
  padding: 0 50px;
}

@media (max-width: 600px) {
  .main-content{
    padding: 0 25px;
  }
}

.mat-dialog-container{
  background-color: #fff;
  color: #151A30;
  position: relative;
  z-index: 1;
  
}

button{
  .mat-button-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.description{
  color: #ff274a;
  font-weight: 500;
}

